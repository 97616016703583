import React from "react";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { Endpoint } from "@schneiderpp/client-endpoint";
import { FormatFloat } from "@schneiderpp/utils-generic";

import "./Add.scss";

interface AddOrderProduct {
    ProductSizeId: number;
    Quantity: number;
    Value: number;
}

interface AddState {
    orderProducts: AddOrderProduct[];
    stage: 1 | 2;
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Order.PostOrderAdd>;
        Info: ParentStateDatasource<typeof Endpoint.Order.GetOrderAddInfo>;
    };
}

interface AddOrderedProductDetails {
    ProductSizeId: number;
    Quantity: number;
    ProductId: number;
    ProductName: string;
    ProductSizeName: string;
    ValuePerPiece: number;
    Value: number;
}

const LOCAL_STORAGE_ORDER_ADD_KEY = "OrderAddKey";

class Add extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        orderProducts: [],
        stage: 1,
        datasource: {
            Add: DataSourceStateIdle,
            Info: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Order.PostOrderAdd, this, "Add", () => this.context);

    private dsOrderAddInfo = ds(Endpoint.Order.GetOrderAddInfo, this, "Info", () => this.context);

    componentDidMount() {
        this.getOrderAddInfo();
        this.localStorageLoadOrder();
    }

    render() {
        if (this.state.stage === 2) {
            return (
                <>
                    <div className="header">
                        <button className="button clear no-padding-left" onClick={() => this.setState({ stage: 1 })}>
                            <span className="button__icon">arrow_back_ios</span> zmień wybrane nagrody
                        </button>
                    </div>
                    <div className="page">
                        <div className="page__header">Zamówienie nagród - podsumowanie</div>
                        <div className="page-table">
                            <table className="order-add__table">
                                <thead>
                                    <tr>
                                        <th className="w-3 t-left">Produkt</th>
                                        <th className="w-2">Rozmiar</th>
                                        <th className="w-2">Liczba Sztuk</th>
                                        <th className="w-2">Wartość w PKT.</th>
                                        <th className="w-3 t-right">Suma w PKT.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.orderedProducts.map((products) => (
                                        <tr key={products.ProductSizeId}>
                                            <td className="t-left">{products.ProductName}</td>
                                            <td>{products.ProductSizeName}</td>
                                            <td>{products.Quantity}</td>
                                            <td>{FormatFloat(products.ValuePerPiece)} PKT</td>
                                            <td className="t-right">{FormatFloat(products.Value)} PKT</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="page-tab-content">
                            <div className="page-details">
                                <div className="page-details__value content-right big">
                                    Wartość Twojego zamówienia to{" "}
                                    <b className="page-details__value-b"> {FormatFloat(this.orderTotalValue)} PKT</b>
                                </div>
                            </div>
                            <div className="page-details">
                                <div className="page-details__value content-right">
                                    Aktualny Stan Twojego konta to{" "}
                                    <b className="page-details__value-b"> {FormatFloat(this.accountBalance)} PKT</b>
                                </div>
                            </div>
                            <div className="page-details">
                                <div className="page-details__value content-right">
                                    Po zamówieniu na Twoim koncie pozostanie{" "}
                                    <b className="page-details__value-b"> {FormatFloat(this.accountBalance - this.orderTotalValue)} PKT</b>
                                </div>
                            </div>
                        </div>
                        <button className="button align-self-end margin-top-10 margin-right-20" onClick={() => this.submit()}>
                            Zamów
                        </button>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Orders.List} className="button clear no-padding-left">
                        <span className="button__icon">arrow_back_ios</span> wróć do listy
                    </Link>
                </div>
                <div className="page order-add__product-page">
                    <div className="page__header">Zamówienie nagród - wybierz produkty</div>
                    <div className="order-add__product-list-wrapper">
                        {this.productList
                            .filter((p) => p.Sizes.some((s) => s.AvailableUnits > 0))
                            .map((product) => {
                                return (
                                    <div className="order-add__product-wrapper" key={product.ProductId}>
                                        <div className={`order-add__product ${this.availableFunds >= product.Value ? "" : "disabled"}`}>
                                            {product.PhotoUrl ? (
                                                <div className="order-add__product__photo">
                                                    <img src={product.PhotoUrl} className="order-add__product__photo__img" alt="" />
                                                </div>
                                            ) : null}
                                            <div className="order-add__product__title">{product.Name}</div>
                                            <div className="order-add__product__description">{product.Description}</div>
                                            <div className="order-add__product__price">
                                                Wartość: <b>{FormatFloat(product.Value)} PKT</b>
                                            </div>
                                            <div className="order-add__product__sizes">
                                                {product.Sizes.filter(s => s.AvailableUnits > 0).map((size) => {
                                                    const orderProductSize = this.state.orderProducts.find(
                                                        (op) => op.ProductSizeId === size.ProductSizeId
                                                    );
                                                    return (
                                                        <div className="order-add__product__size" key={size.ProductSizeId}>
                                                            <div className="order-add__product__size-info">
                                                                <div className="order-add__product__size-info-name">{size.Name}</div>
                                                                <div className="order-add__product__size-info-quantity">
                                                                    Dostępnych sztuk:{" "}
                                                                    <b>{size.AvailableUnits < 0 ? 0 : size.AvailableUnits}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order-add__product__quantity">
                                                                <button
                                                                    className={`button icon small ${
                                                                        !!orderProductSize && orderProductSize.Quantity > 0
                                                                            ? ""
                                                                            : "disabled"
                                                                    }`}
                                                                    onClick={() => this.changeOrderProduct(size.ProductSizeId, -1)}
                                                                >
                                                                    remove
                                                                </button>
                                                                <div className="order-add__product__quantity-value">
                                                                    {!!orderProductSize ? orderProductSize.Quantity : 0}
                                                                </div>
                                                                <button
                                                                    className={`button icon small ${
                                                                        this.availableFunds >= product.Value &&
                                                                        size.AvailableUnits > 0 &&
                                                                        (!orderProductSize ||
                                                                            orderProductSize.Quantity < size.AvailableUnits)
                                                                            ? ""
                                                                            : "disabled"
                                                                    }`}
                                                                    onClick={() => this.changeOrderProduct(size.ProductSizeId, 1)}
                                                                >
                                                                    add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="order-add__footer">
                        <div className="order-add__footer-wrapper">
                            <div className="order-add__footer-content">
                                <div className="order-add__footer__balance">
                                    Stan Twojego konta to <b>{FormatFloat(this.accountBalance)} PKT</b>
                                </div>
                                <div className="order-add__footer__balance">
                                    Wartość Twojego zamówienia to <b>{FormatFloat(this.orderTotalValue)} PKT</b>
                                </div>
                            </div>
                            <div className="order-add__buttons">
                                <button
                                    className={`button ${this.isOrderButtonDisabled ? "disabled" : ""}`}
                                    onClick={() => this.goToNextStage()}
                                >
                                    dalej
                                </button>
                            </div>
                        </div>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get productList() {
        const dsOrderAddInfoData = this.dsOrderAddInfo.dataSourceStorage;
        if (dsOrderAddInfoData.state === "completed") {
            return dsOrderAddInfoData.response.Products;
        }
        return [];
    }

    get accountBalance() {
        const dsOrderAddInfoData = this.dsOrderAddInfo.dataSourceStorage;
        if (dsOrderAddInfoData.state === "completed") {
            return dsOrderAddInfoData.response.Balance;
        }
        return 0;
    }

    get orderedProducts() {
        const dsProducts = this.dsOrderAddInfo;
        if (dsProducts.state !== "completed") {
            return [];
        }
        return this.state.orderProducts.reduce((t, op) => {
            const product = dsProducts.response.Products.find((p) => p.Sizes.findIndex((s) => s.ProductSizeId === op.ProductSizeId) !== -1);
            if (!product) {
                return t;
            }
            const productSize = product.Sizes.find((s) => s.ProductSizeId === op.ProductSizeId);
            if (!productSize) {
                return t;
            }
            t.push({
                ProductSizeId: op.ProductSizeId,
                Quantity: op.Quantity,
                ProductId: product.ProductId,
                ProductName: product.Name,
                ProductSizeName: productSize.Name,
                ValuePerPiece: product.Value,
                Value: op.Quantity * op.Value
            });
            return t;
        }, [] as AddOrderedProductDetails[]);
    }

    get orderTotalValue() {
        return this.state.orderProducts.reduce((sum, op) => sum + op.Quantity * parseFloat(op.Value as any), 0);
    }

    get overlayProps(): OverlayProps {
        const dsAdd = this.dsAdd.dataSourceStorage;
        if (this.dsAdd.state === "pending" || this.dsOrderAddInfo.state === "pending" || this.dsOrderAddInfo.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (dsAdd.state === "completed") {
            return {
                show: true,
                title: "Zamówienie poprawnie dodane",
                description: "Po wysłaniu zamówienia, w zakładce nagrody pojawi się numer listu przewozowego.",
                children: (
                    <div className="overlay__children">
                        <button
                            className="button light"
                            onClick={() =>
                                this.props.history.push({
                                    pathname: ROUTER_HOME.Orders.List,
                                    search: `OrderId=${dsAdd.response.OrderId}`
                                })
                            }
                        >
                            OK
                        </button>
                    </div>
                )
            };
        }
        if (this.dsOrderAddInfo.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsOrderAddInfo.error === "string" ? this.dsOrderAddInfo.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getOrderAddInfo()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    get availableFunds() {
        return parseFloat(this.accountBalance as any) - this.orderTotalValue;
    }

    get isOrderButtonDisabled(): boolean {
        return this.state.orderProducts.length < 1 || this.accountBalance < this.orderTotalValue;
    }

    private changeOrderProduct(productSizeId: number, direction: -1 | 1) {
        const orderProductIndex = this.state.orderProducts.findIndex((e) => e.ProductSizeId === productSizeId);

        if (orderProductIndex === -1) {
            if (direction === -1) {
                return;
            } else if (direction === 1) {
                const product = this.productList.find((p) => p.Sizes.map((s) => s.ProductSizeId).includes(productSizeId));
                if (!product) {
                    return;
                }

                if (this.availableFunds < parseFloat(product.Value as any)) {
                    return;
                }

                const productSize = product.Sizes.find((p) => p.ProductSizeId === productSizeId);

                if (!productSize) {
                    return;
                }

                if (productSize.AvailableUnits < 1) {
                    return;
                }

                this.setState(
                    (p) => ({
                        orderProducts: [...p.orderProducts, { ProductSizeId: productSizeId, Value: product.Value, Quantity: 1 }]
                    }),
                    () => this.localStorageSaveOrder()
                );
            }
        } else {
            const orderProduct = { ...this.state.orderProducts[orderProductIndex] };
            const product = this.productList.find((p) => p.Sizes.map((s) => s.ProductSizeId).includes(productSizeId));

            if (!product) {
                return;
            }

            if (direction === 1 && this.availableFunds < parseFloat(product.Value as any)) {
                return;
            }

            const newQuantity = orderProduct.Quantity + direction < 0 ? 0 : orderProduct.Quantity + direction;

            const productSize = product.Sizes.find((p) => p.ProductSizeId === productSizeId);

            if (!productSize) {
                return;
            }

            if (productSize.AvailableUnits < newQuantity) {
                return;
            }

            orderProduct.Quantity = newQuantity;

            this.setState(
                (p) => {
                    const t = [...p.orderProducts];
                    if (newQuantity <= 0) {
                        t.splice(orderProductIndex, 1);
                    } else {
                        t[orderProductIndex] = orderProduct;
                    }
                    return { orderProducts: t };
                },
                () => this.localStorageSaveOrder()
            );
        }
    }

    private async getOrderAddInfo() {
        await this.dsOrderAddInfo.request({});
        this.validateCurrentOrder();
    }

    private async submit() {
        if (this.isOrderButtonDisabled) {
            return;
        }
        await this.dsAdd.request({
            data: {
                Products: this.state.orderProducts
            }
        });
        if (this.dsAdd.dataSourceStorage.state === "completed") {
            this.localStorateClearOrder();
        }
    }

    private goToNextStage() {
        if (this.isOrderButtonDisabled) {
            return;
        }
        this.setState({ stage: 2 });
    }

    private localStorageSaveOrder() {
        window.localStorage.setItem(LOCAL_STORAGE_ORDER_ADD_KEY, JSON.stringify(this.state.orderProducts));
    }

    private localStorageLoadOrder() {
        const rawLS = window.localStorage.getItem(LOCAL_STORAGE_ORDER_ADD_KEY);

        if (!rawLS) {
            return;
        }

        try {
            const orderAddProducts = JSON.parse(rawLS);

            this.setState({ orderProducts: orderAddProducts });
        } catch (e) {
            return;
        }
    }
    private localStorateClearOrder() {
        window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ADD_KEY);
    }

    private validateCurrentOrder() {
        if (this.orderTotalValue > this.accountBalance) {
            this.setState({ orderProducts: [] });
            return;
        }

        const dsProducts = this.dsOrderAddInfo.dataSourceStorage;

        if (dsProducts.state !== "completed") {
            return;
        }

        this.setState((p) => ({
            orderProducts: p.orderProducts.reduce((t, op) => {
                const opp = { ...op };
                const product = dsProducts.response.Products.find(
                    (p) => p.Sizes.findIndex((s) => s.ProductSizeId === op.ProductSizeId) !== -1
                );

                if (!product) {
                    return t;
                }

                const productSize = product.Sizes.find((s) => s.ProductSizeId === op.ProductSizeId);

                if (!productSize) {
                    return t;
                }

                if (productSize.AvailableUnits < opp.Quantity) {
                    opp.Quantity = productSize.AvailableUnits;
                }

                t.push(opp);

                return t;
            }, [] as AddOrderProduct[])
        }));
    }
}

export default withRouter(Add);
