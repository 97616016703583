import React from "react";
import { ds } from "../../DataSource";
import { Endpoint } from "@schneiderpp/client-endpoint";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";

interface EditAdressFormFields {
    Street: string;
    PostalCode: string;
    City: string;
    AddressComment: string;
    AddressCompany: string;
    Telephone: string;
}

interface EditAdressState {
    fields: EditAdressFormFields;
    fieldErrors: FormValidationError<EditAdressFormFields>[];
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Client.GetClientDetails>;
        NewAddress: ParentStateDatasource<typeof Endpoint.Client.PostEditAddress>;
    };
}

const editAddressFormValidate: FormValidationFunction<EditAdressFormFields> = async (fields) => {
    const errors: Array<FormValidationError<EditAdressFormFields>> = [];
    if (fields.Street.length < 3) {
        errors.push({ fieldName: "Street", code: "StreetTooShort" });
    }
    if (fields.PostalCode.length < 5) {
        errors.push({ fieldName: "PostalCode", code: "PostalCodeTooShort" });
    }
    if (fields.City.length < 3) {
        errors.push({ fieldName: "City", code: "CityTooShort" });
    }
    if (!ValidateTelephone(fields.Telephone)) {
        errors.push({ fieldName: "Telephone", code: "InvalidTelephone" });
    }
    return errors;
};

class EditAddress extends BaseComponent<RouteComponentProps, EditAdressState> {
    state: EditAdressState = {
        fields: {
            Street: "",
            PostalCode: "",
            City: "",
            AddressComment: "",
            Telephone: "",
            AddressCompany: ""
        },
        fieldErrors: [],
        datasource: {
            Details: DataSourceStateIdle,
            NewAddress: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Client.GetClientDetails, this, "Details", () => this.context);

    private dsNewAddress = ds(Endpoint.Client.PostEditAddress, this, "NewAddress", () => this.context);

    private form = new Form<EditAdressFormFields>(this, editAddressFormValidate, (code) => {
        switch (code) {
            case "StreetTooShort":
                return "Nazwa ulicy za krótka";
            case "PostalCodeTooShort":
                return "Kod pocztowy za krótki";
            case "CityTooShort":
                return "Nazwa Miasta za krótka";
            case "InvalidTelephone":
                return "Niepoprawny numer telefonu";
            default:
                return code;
        }
    });

    componentDidMount() {
        this.getDetails();
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link to={{ pathname: ROUTER_HOME.Client.Details }} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span> wróć
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Uzupełnij dane adresowe na potrzeby wysyłki nagród</div>
                    <div className="page-form">
                        <FormTextField
                            config={this.form.getFieldConfig("AddressCompany")}
                            label="Nazwa firmy (jeśli wysyłka nagród będzie na adres firmowy)"
                        />
                        <FormTextField config={this.form.getFieldConfig("Street")} label="ulica, numer budynku/lokalu" />
                        <FormTextField config={this.form.getFieldConfig("City")} label="miasto" />
                        <FormTextField config={this.form.getFieldConfig("PostalCode")} label="kod pocztowy" placeholder="__-___" />
                        <FormTextField config={this.form.getFieldConfig("Telephone")} label="Telefon" />
                        <FormTextField config={this.form.getFieldConfig("AddressComment")} label="informacje dla kuriera" />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                </div>
            </>
        );
    }
    private async getDetails() {
        await this.dsDetails.request({});
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            const response = dsDetailsData.response;
            this.setState(() => ({
                fields: {
                    Street: response.Street,
                    City: response.City,
                    PostalCode: response.PostalCode,
                    AddressComment: response.AddressComment,
                    Telephone: response.Telephone,
                    AddressCompany: response.AddressCompany
                }
            }));
        }
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsNewAddress.request({
                data: {
                    Street: this.state.fields.Street,
                    City: this.state.fields.City,
                    PostalCode: this.state.fields.PostalCode,
                    AddressComment: this.state.fields.AddressComment,
                    Telephone: this.state.fields.Telephone,
                    AddressCompany: this.state.fields.AddressCompany
                }
            });
            if (this.dsNewAddress.dataSourceStorage.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Client.Details });
            }
        }
    }
}

export default withRouter(EditAddress);

function ValidateTelephone(telephone: string): boolean {
    if (typeof telephone !== "string") return false;
    telephone = telephone.trim();
    return new RegExp(/^(\+{1}[0-9]{1,3}|)[0-9]{9}$/).test(FormatTelephone(telephone));
}

function FormatTelephone(telephone: string): string {
    return telephone.replace(/[ \-()]/g, "");
}
